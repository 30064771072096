.Section {
  display: flex;
}

.Left {
  background-color: var(--contact-bg-left-color);

  width: 50%;
  padding: 30px 0;
  padding-left: 10px;
  position: relative;
}
.LeftContent {
  padding: 0 40px;
}
.Right {
  background-color: var(--contact-bg-right-color);

  width: 50%;
  padding: 80px 60px;
}

.Title {
  width: 55%;
  padding-top: 10px;
  border-top: 8px solid var(--section-border-color);
  font-size: 45px;
  color: #029fe2;
  text-align: right;
  font-weight: bold;
}
.Address {
  margin-top: 40px;
  color: #fefefe;
}
.AddressItem {
  margin-bottom: 5px;
}

.AddressName {
  font-size: 19px;
}

.AddressValue {
  font-size: 24px;
  color: var(--green-color);
}

.Coords {
  position: absolute;
  right: 40px;
  bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  border: 2px solid #029fe2;
  padding: 10px;
}
.CoordsData > div {
  color: var(--green-color);
}

.CoordsIcon {
  width: 25px;
}

.FormTitle {
  font-size: 50px;
  color: var(--text-color);
  margin-bottom: 30px;
}
.Input {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #818181;
  font-size: 20px;
  padding-bottom: 5px;
  color: #818181;
  margin-bottom: 35px;
  outline: none;
}

.Textarea {
  display: block;
  width: 100%;
  height: 100px;
  background-color: var(--textarea-color);
  border: none;
  resize: none;
  outline: none;
  color: #818181;
  font-size: 18px;
  padding: 5px;
}
.Btn {
  text-align: right;
}
.Button {
  margin-left: auto;
  background-color: transparent;
  border: 2px solid #029fe2;
  color: #029fe2;
  font-size: 20px;
  padding: 10px 20px;
  margin-top: 40px;
}

@media screen and (max-width: 991px) {
  .Section {
    display: block;
  }
  .Title {
    text-align: left;
  }
  .Left {
    width: 100%;
  }

  .Right {
    width: 100%;
  }

  .AddressName {
    font-size: 16px;
  }
  
  .AddressValue {
    font-size: 18px;
  }
  .Coords {
    display: none;
  }
}


@media screen and (max-width: 768px) {
 

  .Left {
    width: 100%;
  }

  .Right {
    padding: 15px;
  }

  .FormTitle {
    font-size: 35px;
  }
  .Title {
    font-size: 35px;
  }
}



@media screen and (max-width: 480px) {
  .LeftContent {
    padding: 0 15px;
  }
  .AddressValue {
    font-size: 16px;
  }
  .Title {
    font-size: 28px;
  }
  .FormTitle {
    font-size: 28px;
  }
  .Coords {
    right: 25px;
    bottom: 15px;
  }
}

@media screen and (max-width: 360px) {
  .Coords {
    width: 150px;
    padding: 5px;
  }
}