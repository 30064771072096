.wrapper {
  min-height: 100vh;
  width: 100%;
  background: var(--header-mobi-color);
  padding: 60px 5vw;
  color: var(--text-color);
}

.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
}

.homeLink {
  display: flex;
  align-items: center;
  color: var(--text-color);
  font-size: 2rem;
  /*text-decoration: underline;*/
}
.arrowIcon {
  margin-right: 8px;
  transform: rotate(180deg);
  fill: var(--text-color);
}

.inputsItem {
  display: flex;
  align-items: center;
  gap: 8px;
}

.searchInput {
  height: 40px;
  background: none;
  width: 80px;
  border: 1px solid var(--text-color);
  padding: 10px;
  font-size: 16px;
  color: var(--text-color);
}

.inputsGroup {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

@media screen and (max-width: 911px) {
  .inputsGroup {
    flex-direction: column;
    align-items: flex-end;
  }
}

.searchInput::placeholder {
  opacity: 0.4;
  color: var(--text-color);
}

.container {
  padding: 40px 0;
}

.item {
  padding: 20px 0;
}

.itemTitle {
  margin-bottom: 12px;
  font-size: 21px;
  text-decoration: underline;
  color: var(--text-color);
}

.itemDescription {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

@media (max-width: 911px) {
  .wrapper {
    padding: 40px 20px;
  }
}
