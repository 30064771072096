.slider {
  width: 100%;
  height: 100%;
}

.slider .slick-list {
  height: 100%;
}

.slider .slick-list .slick-track {
  height: 100%;
}
.slider .slick-list .slick-track > div {
  height: 100%;
}
.slider .slick-list .slick-track > div > div {
  height: 100%;
}
.arrow.slick-disabled {
  display: none;
}
.arrow::before {
  display: none;
}
 .slider .slick-next {
  right: 120px;
}

.slider .slick-prev {
  left: 320px;
  z-index: 60;
}

@media screen and (max-width: 991px) {
  .slider .slick-next  {
    right: 25px;
  }
  .slider .slick-prev {
    left: 35px;
  }
}


@media screen and (max-width: 480px) {
  .slider .slick-prev {
    left: 15px;
  }
}