.Section {
  height: 100vh;
  display: flex;
  color: var(--text-color);
}

.Title {
  font-size: 35px;
  color: #029fe2;
  text-align: right;
  font-weight: bold;
}
.List {
  margin-top: 40px;
}
.ListItem {
  padding: 8px 16px;
  color: var(--green-color);
  font-size: 22px;
  margin-bottom: 18px;
  border: 1px solid var(--green-color);
  border-radius: 3px;

  transition: color 192ms, border 192ms;
}
.ListItem:hover {
  color: var(--green-color-hovered);
  border: 1px solid var(--green-color-hovered);
}
.ListItemText {
  width: 100%;
  display: flex;
  align-items: center;
  color: inherit;
}

.Left {
  width: 50%;
  height: 100%;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 60px;
}
.LeftContent {
  padding-left: 20px;
  padding-top: 15px;
  border-top: 8px solid var(--section-border-color);
}
.Right {
  width: 50%;
  height: 100%;
}

.ArrowIcon {
  margin-left: auto;
  fill: var(--green-color);

  transition: all 192ms;
}
.ListItem:hover .ArrowIcon {
  fill: var(--green-color-hovered);
}

@media screen and (max-width: 991px) {
  .Right {
    margin-bottom: 90px;
  }
}

.RightContent {
  height: 100%;
  padding-top: 100px;
  padding-left: 30px;
  background: url(./Scema.png) center no-repeat;
  background-size: 500px 400px;
  position: relative;
}

.Triangle {
  position: absolute;
  width: 100px;
  top: 15px;
  right: 30px;
}

.TrackName,
.TrackDistance {
  font-weight: bold;
  font-size: 22px;
}

@media screen and (max-width: 991px) {
  .ListItem {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .Section {
    height: auto;
    display: block;
  }

  .Left {
    width: 100%;
  }

  .Title {
    text-align: left;
    padding-top: 10px;
    border-top: 4px solid var(--section-border-color);
    width: 50%;
  }
  .LeftContent {
    border-top: none;
    padding: 0;
  }
  .Right {
    width: 100%;
    height: 500px;
  }

  .RightContent {
    padding-top: 0;
    padding-left: 20px;
    background-size: contain;
  }

  .Triangle {
    top: 0;
    right: 20px;
    width: 80px;
  }

  .TrackName,
  .TrackDistance {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .Title {
    font-size: 28px;
  }
}

@media screen and (max-width: 480px) {
  .Title {
    font-size: 20px;
  }
}
