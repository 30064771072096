@font-face {
  font-family: "Bahnschrift";
  src: url("./fonts/Bahnschrift.ttf") format("ttf");
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Bahnschrift, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no-scroll {
  overflow: hidden;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slick-prev,
.slick-next {
  display: flex !important;
  align-items: center !important;
  width: auto !important;
  height: auto !important;
  padding: 8px 14px !important;
  border-radius: 3px;
}

.slick-prev span, .slick-prev svg,
.slick-next span, .slick-next svg {
  transition: all 92ms;
}
.slick-prev svg {
  margin-right: 6px;
}
.slick-next svg {
  margin-left: 6px;
}

.slick-prev:hover span,
.slick-next:hover span {
  color: var(--green-color-hovered);
}
.slick-prev:hover svg,
.slick-next:hover svg {
  fill: var(--green-color-hovered);}

.arrow svg {
  fill: var(--green-color);
}
.arrow span {
  height: auto;
  font-size: 1.4rem;
  color: var(--green-color);
}

.slick-prev svg {transform: rotate(180deg);
}
.slick-next svg {
}

.news .slick-prev,
.news .slick-next {
  top: 100px !important;
  padding: 8px 16px;
  /*bottom: 0 !important;*/
  border: 1px solid var(--green-color);
}

.news .slick-prev {
  left: 35px !important;
  right: auto !important;
}

.news .slick-next {
  left: 40% !important;
  right: auto !important;
}

@media (max-width: 991px) {
  .news .slick-slider.slider {
    height: calc(100vh - 40px);
  }

  .news .slick-prev,
  .news .slick-next {
    bottom: 0 !important;
    top: auto !important;
  }

  .news .slick-prev {
    left: auto !important;
    right: calc(50vw + 20px) !important;
  }

  .news .slick-next {
    left: calc(50vw + 20px) !important;
    right: auto !important;
  }
}

.infrastructureSlider .slick-next,
.infrastructureSlider .slick-prev {
  padding: 8px 16px;
  border: 1px solid var(--green-color);
}

.infrastructureSlider .slick-prev {
  top: auto;
  bottom: 30px;
  left: auto !important;
  right: 325px;
}

.infrastructureSlider .slick-next {
  top: auto;
  bottom: 30px;
}

@media (max-width: 991px) {
  .infrastructureSlider .slick-prev {
    top: auto;
    bottom: -60px;
    left: 50px !important;
    right: auto;
  }

  .infrastructureSlider .slick-next {
    top: auto;
    bottom: -60px;
    left: 225px;
    right: auto;
  }
}
