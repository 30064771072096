.Section {
  background-color: var(--bg-color);
  display: flex;
  height: 100vh;
  gap: 30px;
}

.Triangle {
  position: absolute;
  width: 150px;
  bottom: 30px;
  right: 0;
  pointer-events: none;
}

.Left {
  width: 50%;
  height: 100%;
  padding-left: 15px;
  padding-top: 40px;
}

.Right {
  width: 45%;
  height: 100%;
  margin: 0 auto;
  padding-right: 40px;
}
.RightContent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 4px solid var(--section-border-color);
  position: relative;
}
.Content {
  border-top: 8px solid var(--section-border-color);
  padding-top: 15px;
  display: flex;
  flex-flow: column;
  height: 100%;
  padding-left: 20px;
  border-bottom: 8px solid var(--section-border-color);
}
.Title {
  font-size: 35px;
  color: var(--blue-light);
  text-align: right;
  font-weight: bold;

  transition: color 92ms;
}
.Title:hover {
  color: var(--blue-hovered);
}

.LeftDate {
  font-size: 1.8rem;
  color: var(--green-color);
  margin-bottom: 18px;
}

.Events {
  color: var(--text-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  overflow: auto;
}

.EventWrapper {
  margin: auto 0;
}
.Event {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  width: 100%;
  padding: 15px;
  /*background: rgba(255, 255, 255, 0.1);*/
  border: 1px solid var(--text-color);
  border-radius: 3px;
  /*text-decoration: underline;*/
  color: var(--text-color);

  transition: border-color 92ms;
}
.Event:hover {
  border: 1px solid var(--text-hovered);
}

.Event svg {
  transition: fill 92ms;
}
.Event:hover svg {
  fill: var(--text-hovered)
}

.EventDate {
  margin-bottom: 18px;
  font-size: 1.4rem;
}

.EventsPlaceholder {
  margin: auto 0;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}

.arrowIcon {
  flex-shrink: 0;
  margin-left: auto;
  fill: var(--text-color);
}

@media screen and (max-width: 1100px) {
  .Section {
    flex-direction: column;
  }

  .Right {
    width: 100%;
  }

  .RightContent {
    padding-bottom: 50px;
  }

  .Events {
    padding-right: 10px;
  }

  .Event {
    padding: 10px;
    font-size: 16px;
  }

  .EventWrapper {
    margin-bottom: 1rem;
  }

  .EventsPlaceholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 991px) {
  .Section {
    height: auto;
    padding-right: 20px;
    position: relative;
  }
  .Section::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 15px;
    width: 50%;
    height: 4px;
    background-color: var(--section-border-color);
  }
  .Section::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 15px;
    width: 50%;
    height: 4px;
    background-color: var(--section-border-color);
  }
  .Left {
    width: 100%;
    padding: 0;
    padding-left: 20px;
    padding-top: 30px;
  }
  .Right {
    width: 100%;
    padding: 0;
  }

  .Content {
    padding: 0;
    border: none;
    gap: 25px;
  }

  .RightContent {
    padding: 0;
    border: none;
  }

  .Title {
    font-size: 20px;
    text-align: left;
  }

  .Events {
    align-items: flex-start;
    justify-content: center;
    font-size: 16px;
  }

  .Triangle {
    width: 50px;
    bottom: -60px;
    right: 10px;
  }
  .LeftDate {
    font-size: 1.4rem;
  }
  .EventDate {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  .Left {
    padding-left: 10px;
  }
  .Section {
    padding-right: 0;
  }
}
