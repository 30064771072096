.wrapper {
  min-height: 100vh;
  width: 100%;
  background: var(--header-mobi-color);
  padding: 60px 5vw;
  color: var(--text-color);
}

.homeLink {
  color: var(--text-color);
  font-size: 2rem;
  /*text-decoration: underline;*/
}

.arrowIcon {
  margin-right: 8px;
  transform: rotate(180deg);
  fill: var(--text-color);
}

.title {
  margin: 20px 0 0;
  font-size: 40px;
}

.item {
  padding: 20px 0;
}

.itemTitle {
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 12px;
}

.itemDate {
  color: var(--green-color);
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 18px;
}

.frameContainer iframe {
  width: 100%;
  aspect-ratio: 16/9;
  height: 100%;
}
