.Header {
  background-color: var(--bg-color);
  height: 70px;
}
.MobileMenu {
  transform: translateX(-100%);
  transition: all 0.3s;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  background-color: var(--mobile-menu-bg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  height: 100%;
}

.MobileMenu .ChannelName {
  color: var(--mobile-menu-color);
  text-decoration: underline;
}

.MobileMenu .Channel {
  padding: 30px;
}

.MobileMenu ul li a {
  color: var(--mobile-menu-color);
  font-size: 25px;
}

.MobileMenu ul li {
  margin-bottom: 10px;
  transition: 0.3s all;
}
.MobileMenu ul li:hover {
  transform: translateX(15px);
}

.MobileMenu ul {
  padding: 30px;
}
.MobileMenuOpen {
  pointer-events: auto;
  transform: translateX(0);
}
.MobileNav {
  display: none;
  align-items: center;
  height: 100%;
}

.Inner {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.Left,
.Right {
  display: flex;
}
.Left,
.Right a {
  color: var(--text-color);
  text-decoration: none;
}
.Logo {
  display: flex;
  align-items: center;
  height: 100%;
}

.Logo > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LogoElement {
  width: 250px;
  height: auto;
  background: var(--header-icon-bg);
  padding: 0 20px;
  margin-right: 8px;
}

.LogoCamera {
  width: 60px;
  height: auto;
  background: var(--header-icon-bg);
  /*height: 100%;*/
  padding: 0 15px;
  display: flex;
  align-items: center;
}
.Nav {
  margin-left: 30px;
}
.Menu {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 100%;
}

.Menu li a {
  color: var(--text-color);
}

.Channel {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.ChannelName {
  color: var(--text-color);
  margin-right: 10px;
}
.ChannelIcon {
  width: 25px;
  height: auto;
}

.linkButton {
  padding: 0 15px;
  margin-right: 8px;
  background: var(--header-icon-bg);
}
.linkIcon {
  height: 100%;
  fill: var(--text-color)
}

.Contact {
  background-color: #029fe2;
  color: var(--bg-color);
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.Hamburger {
  width: 35px;
  height: 30px;
  margin: 10px 10px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.Hamburger span {
  background-color: #029fe2;
  position: absolute;
  border-radius: 2px;
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  width: 100%;
  height: 3px;
}
.Hamburger span:nth-child(1) {
  top: 0;
  left: 0;
}
.Hamburger span:nth-child(2) {
  top: 13px;
  left: 0;
  opacity: 1;
}
.Hamburger span:nth-child(3) {
  bottom: 0;
  left: 0;
}

.Open span:nth-child(1) {
  transform: rotate(45deg);
  top: 13px;
}
.Open span:nth-child(2) {
  opacity: 0;
}
.Open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 13px;
}
@media screen and (max-width: 991px) {
  .LogoElement {
    width: 200px;
  }

  .Menu {
    gap: 10px;
  }

  .linkButton {
    margin: 0;
  }
}

@media screen and (max-width: 1270px) {
  .Right {
    display: none;
  }
  .Left {
    justify-content: space-between;
    width: 100%;
  }
  .Header {
    background-color: var(--header-mobi-color);
  }
  .Logo > div + div {
    display: none;
  }
  .Nav {
    display: none;
  }
  .MobileNav {
    display: flex;
  }
  .MobileMenu {
    display: block;
  }
  .LogoCamera {
    display: flex;
    align-items: center;
    background: none !important;
  }
  .LogoElement {
    background: none !important;
  }
}

@media screen and (max-width: 360px) {
  .LogoElement {
    width: 150px;
  }
}
